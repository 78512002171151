<template>
    <modal class="userBindCordModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">绑员工卡 </div>
        <div class="modal-body">
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">工号：</span>
                    <div class="from-input"><input type="text" :value="userInfo?.User_Code" readonly/></div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">用户名：</span>
                    <div class="from-input"><input type="text" :value="userInfo?.Login_Name" readonly/></div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">考勤卡号：</span>
                    <div class="from-input"><input type="text" v-model="ACCESS_CARDNUMBER" maxlength="32" /></div>
                    <div class="read-card" @click="readCard()">读卡</div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected" @click="confirm()"> 确认</button>
        </div>
        
    </modal>
</template>

<script>
// 绑定员工卡
export default {
    name:'userBindCordModel',
    props:{
        isShow:Boolean,
    },
    data(){
        return {
            userInfo:null,
            //卡号
            ACCESS_CARDNUMBER:'',
        }
    },
    mounted(){
        this.userInfo= this.$auth.getUserInfo();
    },
    watch:{
        isShow(newVal){
            if(newVal){
                this.ACCESS_CARDNUMBER="";
            }
        }
    },
    methods:{
        hide(){
            this.$emit("closeModel");
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.ACCESS_CARDNUMBER=d.data.snr;
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        confirm(){
            if(!this.ACCESS_CARDNUMBER){
                this.$message.warning("考勤卡号不能为空!");
                return;
            }
            
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.UserBindCord",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                accountID:userInfo.User_ID,
                ACCESS_CARDNUMBER:this.ACCESS_CARDNUMBER
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    this.hide();
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('员工卡绑定失败：'+e.message);
                console.log('员工卡绑定失败：',e);
            })
        }
    }
}
</script>

<style lang="scss">
@import './userBindCordModel.scss'
</style>