<template>
    <div class="changePwd">
       <div class="scroll-box">
            <div class="tr-li">
                <div class="lable-txt">工号：</div>
                <div class="from-input"><input type="text" :value="userInfo?.User_Code" readonly/></div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">用户名：</div>
                <div class="from-input"><input type="text" :value="userInfo?.Login_Name" readonly/></div>
            </div>
            <div class="info-box">
                <div class="tr-li">
                    <div class="lable-txt">旧密码：</div>
                    <key-board-input class="from-input"
                        type="password" v-model="oldPwd" :maxlength="32" placeholder="请输入旧密码"
                        :isBoard="true"
                        :isKeyEnterClose="true"
                        :isBlurClose="true"
                        :keyOptions="{isKeyDownEnter:true}"
                    ></key-board-input>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">新密码：</div>
                    <key-board-input class="from-input"
                        type="password" v-model="newPwd" :maxlength="32" placeholder="请输入新密码"
                        :isBoard="true"
                        :isKeyEnterClose="true"
                        :isBlurClose="true"
                        :keyOptions="{isKeyDownEnter:true}"
                    ></key-board-input>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">确认密码：</div>
                    <key-board-input class="from-input"
                        type="password" v-model="newPwd2" :maxlength="32" placeholder="请再次输入新密码"
                        :isBoard="true"
                        :isKeyEnterClose="true"
                        :isBlurClose="true"
                        :keyOptions="{isKeyDownEnter:true}"
                    ></key-board-input>
                </div>
            </div>
            
        </div>
        <div class="footer-box">
            <button class="btn" @click="$router.back()">返回</button>
            <button class="btn selected" @click="userBingCordIshow=true">绑员工卡</button>
            <button class="btn selected" @click="save()">确定</button>
        </div>
        <!-- 绑员工卡 -->
        <modal-load :isShow="userBingCordIshow" >
            <user-bind-cord-model :isShow="userBingCordIshow"  @closeModel="userBingCordIshow=false" ></user-bind-cord-model>
        </modal-load>
    </div>
</template>

<script>
import userBindCordModel from './userBindCordModel/userBindCordModel.vue'
//用户密码修改
export default {
    name:'changePwd',
    components:{
        userBindCordModel
    },
    data(){
        return {
            userInfo:{},
            oldPwd:'',
            newPwd:'',
            newPwd2:"",
            userBingCordIshow:false
        }
    },
    watch:{
        cardNo(){
            this.memberInfo=null;
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        this.userInfo= this.$auth.getUserInfo();
    },
    methods:{
        /**保存修改 */
        save(){
            if(this.newPwd!=this.newPwd2){
                this.$message.warning('新密码与确认密码不一致,请重新输入!');
                return
            }
            const loading = this.$loading({
                text: "保存操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.ResetPassword",{
                Operator_Name:this.userInfo?.Login_Name,
                User_ID:this.userInfo?.User_ID,
                accountID:this.userInfo?.User_ID,
                oldPwd:this.oldPwd,
                newPwd:this.newPwd
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error("修改密码失败："+d.ResponseHeader.ResultDesc);
                    return;
                }
                this.$message.success("修改密码成功");
            }).catch((e)=>{
                loading.close();
                this.$message.error('修改密码失败：'+e);
                console.log('修改密码失败：'+e);
            })
        }
    }
}
</script>

<style lang="scss">
@import "./changePwd.scss";
</style>